import React from 'react'
import {Container, Row, Col} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {withPrefix} from 'gatsby';
import PatientenkarteiFeaturette from '../components/patientenkarteiFeaturette';
import VerschreibungFeaturette from '../components/VerschreibungFeaturette';
import DruckFeaturette from '../components/DruckFeaturette';
import {Pricing} from '../components/pricing';
import OrdinationsManagementImage from '../components/OrdinationsManagement';

const Ordinationsmanagement = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Praxis"
             googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
             fbUrl="https://medoboard.com/ordinationsmanagement"
             fbImage={withPrefix('medoboard-fb.png')}
             fbTitle="Medoboard - optimiert Ihre Praxis"
             fbDescription="Medoboard unterstützt Sie in ihrer täglichen Arbeit mit dem Patienten damit Sie mehr Zeit für das Wesentliche haben"
             keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]}/>
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <OrdinationsManagementImage/>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Ordinationsmanagement auf einen Blick</h2>
                    <h4 className="text-muted mb-5">Medoboard unterstützt Sie in Ihrer täglichen Arbeit mit dem Patienten
                        optimal</h4>
                    <p className="lead">
                        medoboard optimiert Ihre Ordination mit folgenden intelligenten Features:
                    </p>
                    <ul className="lead">
                        <li>Patientenstammdaten-Eingabe direkt am Tablet</li>
                        <li>Intuitives Wartelisten-Management</li>
                        <li>Optimale Unterstützung durch Textvorlagen</li>
                        <li>Verschreibungen werden automatisch an den aktuellsten Apothekerverlag-Release angepasst</li>
                        <li>Honorarnotendruck so einfach wie noch nie: Einmal konfigureren, immer wiederverwenden</li>
                    </ul>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <PatientenkarteiFeaturette imageTitle="Patientenkartei" imageText="500x500"
                                               heading="Alle Patienten..."
                                               subheading="... mit einem Tastendruck" text="Der medizinische Alltag ist geprägt von komplexen Entscheidungen,
                        aufwendigen Diagnosen und zeitraubender Administration."/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <VerschreibungFeaturette imageTitle="Verschreibung" featuretteAlign="featurette-right"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <DruckFeaturette imageTitle="Druck"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
);

export default Ordinationsmanagement;